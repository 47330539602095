import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Preferences } from '@capacitor/preferences';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes('login') || req.url.includes('verify')) {
      return next.handle(req);
    }
  
    return from(Preferences.get({ key: 'currentUser' })).pipe(
      switchMap(res => {
        const value = (res.value || '').trim();
  
        const encryptedToken = value;
        const decryptedToken = CryptoJS.AES.decrypt(encryptedToken, 'sk!_Auth_Nectica_2023!').toString(CryptoJS.enc.Utf8);
  
        let headers = req.headers
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Authorization', `Bearer ${decryptedToken}`);
  
        headers = headers.delete('some-unwanted-header');
  
        const authReq = req.clone({ headers });
        return next.handle(authReq);
      })
    );
  }
}
