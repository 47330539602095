import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { AuthService } from 'src/app/outside/auth/services/auth.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  isAndroidOriOS!: boolean;
  showMenu: boolean = false;

  menu: any[] = [
    {
      name: 'MI CUENTA',
      navigate: 'cuenta',
    },
    {
      name: 'GASTOS',
      navigate: 'gastos',
    },
    {
      name: 'UNIDADES',
      navigate: 'unidades',
    },
    {
      name: 'RESERVAS',
      navigate: 'reservas',
    },
    {
      name: 'REPORTES',
      navigate: 'reportes',
    },
  ];

  constructor(
    private platform: Platform,
    private authService: AuthService,
    private router: Router
  ) {
    this.isAndroidOriOS =
      this.platform.is('android') || this.platform.is('ios');
  }

  ngOnInit() {}

  async logout() {
    try {
      await this.authService.logout();
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
    this.router.navigateByUrl('auth')
  }

  showMenuButon(): void {
    this.showMenu = !this.showMenu;
  }
}
