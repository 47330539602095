import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class NoticeService {
  private toast: any = null;

  constructor(private toastController: ToastController) {}

  async presentToast(message: string, color: string = 'primary') {
    if (this.toast) {
      this.toast.dismiss();
    }

    let buttons = [];

    if (color !== 'success') {
      buttons.push({
        text: 'Ocultar',
        role: 'cancel',
        handler: () => {
          this.toast = null;
        }
      });
    }

    this.toast = await this.toastController.create({
      message: message,
      duration: 4000,
      color: color,
      position: 'top',
      buttons: buttons
    });

    this.toast.present();
  }
}
