import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { catchError, Observable, throwError, Subject } from 'rxjs';
import { NoticeService } from '../shared/services/notice.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  errorSubject = new Subject<number>();
  error$ = this.errorSubject.asObservable();

  constructor(private router: Router, private noticeService: NoticeService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 403) {
            this.noticeService.presentToast('Sesión expirada', 'danger');
            Preferences.remove({ key: 'currentUser' });
            this.errorSubject.next(error.status);
            this.router.navigateByUrl('');
          } else if (error.status === 500) {
            this.router.navigateByUrl('/error');
          }
        }
        return throwError(() => error);
      })
    );
  }
}
