import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TabsComponent } from './tabs.component';
import { AdminGuard } from 'src/app/guard/admin.guard';

const routes: Routes = [
  {
    path: '',
    component: TabsComponent,
    children: [
      {
        path: 'unidades',
        loadChildren: () =>
          import('../../inside/pages/unidades/unidades.module').then(
            (m) => m.UnidadesPageModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'gastos',
        loadChildren: () =>
          import('../../inside/pages/gastos/gastos.module').then(
            (m) => m.GastosPageModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'cuenta',
        loadChildren: () =>
          import('../../inside/pages/cuenta/cuenta.module').then(
            (m) => m.CuentaPageModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'reservas',
        loadChildren: () =>
          import('../../inside/pages/reservas/reservas.module').then(
            (m) => m.ReservasPageModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'reportes',
        loadChildren: () =>
          import('../../inside/pages/reportes/reportes.module').then(
            (m) => m.ReportesPageModule
          ),
        canActivate: [AdminGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TabsRoutingModule {}
