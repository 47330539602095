<ion-header *ngIf="isAndroidOriOS">
  <ion-toolbar class="p-0 border-b-primary border-2 flex justify-between items-center">
    <ion-buttons slot="start" disabled>
      <ion-button disabled>
        <ion-icon></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="flex items-center">
      <img src="assets/img/logo.png" alt="" class="h-10 w-10 mx-auto">
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="logout()">
        <ion-icon name="log-out-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-tabs class="bg-white">
  <ion-tab-bar slot="bottom" *ngIf="isAndroidOriOS" class="border border-t-2 border-t-primary">

    <ion-tab-button tab="cuenta">
      <ion-icon name="person-outline"></ion-icon>
      <ion-label>Mi Cuenta</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="gastos">
      <ion-icon name="logo-usd"></ion-icon>
      <ion-label>Gastos</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="unidades">
      <ion-icon name="business-outline"></ion-icon>
      <ion-label>Unidades</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="reservas">
      <ion-icon name="book-outline"></ion-icon>
      <ion-label>Reservas</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="reportes">
      <ion-icon name="stats-chart-outline"></ion-icon>
      <ion-label>Reportes</ion-label>
    </ion-tab-button>

  </ion-tab-bar>
</ion-tabs>

<nav class="bg-primary fixed w-full shadow-sm z-50 text-white" *ngIf="!isAndroidOriOS">
  <div class="mx-auto max-w-6xl px-4 2xl:px-0">
    <div class="flex h-20 items-center justify-between w-full px-5">
      <div class="flex items-center">
        <img src="assets/img/logo.png" alt="" width="160" height="160"
          class="w-16 filter brightness-0 invert transition-transform duration-300 transform hover:scale-105">
      </div>
      <div class="hidden md:block h-full px-5">
        <div class="ml-10 flex items-baseline space-x-4 h-full" id="mega-menu-full">
          <ng-container *ngFor="let m of menu">
            <button #hover [routerLink]="m.navigate" routerLinkActive="active" ariaCurrentWhenActive="page"
              class="h-full text-white px-1 py-3 rounded text-xs font-bold hover:text-third transition-colors hover:text-[#579dff]">
              {{ m.name | uppercase }}
            </button>
          </ng-container>
          <button (click)="logout()" #hover routerLinkActive="active" ariaCurrentWhenActive="page"
            class="h-full text-white px-1 py-3 rounded text-xs font-bold hover:text-third transition-colors hover:text-[#579dff]">
            CERRAR SESIÓN
          </button>
        </div>
      </div>
      <div class="-mr-2 flex md:hidden">
        <button type="button" class="inline-flex items-center justify-center rounded-md bg-transparent p-2 text-white"
          aria-controls="mobile-menu" aria-expanded="false" id="boton" (click)="showMenuButon()">
          <span class="sr-only">Open main menu</span>

          <svg class="block h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </button>
      </div>
    </div>

    <ng-container *ngIf="showMenu">
      <div
        class="container max-w-full pb-2 text-center text-white font-bold bg-primary animate__animated animate__fadeIn animate__faster">
        <ng-container *ngFor="let m of menu">
          <div class="mx-auto p-2.5">
            <button #hover [routerLink]="m.navigate" (click)="showMenuButon()" routerLinkActive="active"
              ariaCurrentWhenActive="page"
              class="h-full text-white px-1 py-3 rounded text-xs font-bold hover:text-third transition-colors">
              {{ m.name | uppercase }}
            </button>
          </div>
        </ng-container>
        <button (click)="logout()" #hover (click)="showMenuButon()" routerLinkActive="active"
          ariaCurrentWhenActive="page"
          class="h-full text-white px-1 py-3 rounded text-xs font-bold hover:text-third transition-colors">
          CERRAR SESIÓN
        </button>
      </div>
    </ng-container>
  </div>
</nav>