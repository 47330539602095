import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  of,
} from 'rxjs';
import { Preferences } from '@capacitor/preferences';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _BASE_URL: string = environment.url;
  private loggedIn = new BehaviorSubject<boolean>(false);

  constructor(private _HTTP: HttpClient) {
    this.checkLoggedIn();
  }

  login(email: string, password: string): Observable<boolean> {
    const data = new FormData();
    data.append('username', email);
    data.append('password', password);

    return this._HTTP.post<any>(`${this._BASE_URL}/login/auth`, data).pipe(
      map((response) => {
        if (response && response.data.token) {
          this.loggedIn.next(true);

          const encryptedToken = CryptoJS.AES.encrypt(
            response.data.token,
            'sk!_Auth_Nectica_2023!'
          ).toString();

          Preferences.set({
            key: 'currentUser',
            value: `${encryptedToken}`,
          });

          return true;
        }
        this.loggedIn.next(false);
        return false;
      }),
      catchError((error) => {
        console.error('Error en la solicitud de inicio de sesión:', error);
        this.loggedIn.next(false);
        return of(false);
      })
    );
  }

  async getCurrentUser(): Promise<any> {
    const userString = (await Preferences.get({ key: 'currentUser' })) || {
      value: null,
    };
    return userString.value ? userString.value : null;
  }

  async logout() {
    await Preferences.remove({ key: 'currentUser' });
    this.loggedIn.next(false);
    this.login('', '');
  }

  isAuthenticated(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  private async checkLoggedIn() {
    const userString = await Preferences.get({ key: 'currentUser' });
    if (userString) {
      this.loggedIn.next(true);
    }
  }

  setLoggedIn(value: boolean) {
    this.loggedIn.next(value);
  }
}
